import MyProjects from '@/views/my-projects/pages/MyProjects.vue';
import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    name: 'myProjects',
    path: '/my-projects/',
    component: MyProjects,
    meta: {
      dontScrollBetween: true,
      keepAlive: true,
      layout: 'full',
      auth: true,
    },
  },
  {
    name: 'offersForMyProject',
    path: '/my-projects/offers-for-my-project/:projectId',
    component: MyProjects,
    meta: {
      dontScrollBetween: true,
      keepAlive: true,
      layout: 'full',
      auth: true
    }
  }
];
export default routes;
