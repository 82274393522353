
import { User } from '@/models/User';
import { BannerUserState } from '@/models/UserState';
import useAvatarState from '@/state/avatarState';
import {
  computed, ComputedRef, defineComponent, PropType, ref, toRefs, watchEffect
} from 'vue';
import Cookie from '@/components/svg-components/Cookie.vue';
import FallbackAvatar from '@/components/svg/logo/logo-circles-only.svg?inline';
import store from '@/store';
import { AvatarCache, LOADING } from '@/store/avatarCache';
import useQuickInfo from '@/state/quickInfo';
import {
  templateRef, useDocumentVisibility, useElementVisibility, useTimeoutPoll, whenever
} from '@vueuse/core';
import { REFRESH_INTERVAL_BANNER_ONLINE_STATUS } from '@/models/IntervalTimes';

export default defineComponent({
  components: {
    Cookie,
    FallbackAvatar,
  },
  props: {
    userId: {
      type: String,
      default: undefined,
    },
    size: {
      type: Number as PropType<120 | 60 | 50 | 40 | 32 | 26>,
      default: 120,
    },
    showOnlineIndicator: {
      type: Boolean,
      default: false,
    },
    showCookies: {
      type: Boolean,
      default: false,
    },
    userIsOnline: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },
    amountOfCookies: {
      type: Number as PropType<number | undefined>,
      default: undefined,
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const {
      size,
      userId,
      userIsOnline,
      amountOfCookies
    } = toRefs(props);
    const {
      useNativeBrowserScaling
    } = useAvatarState();

    const documentVisibility = useDocumentVisibility();

    const hasBeenVisible = ref(false);
    const avatarUserId = ref<string>('user');
    const viewUser = ref<BannerUserState | null>(null);
    const {
      setShowContactHistory,
      quickInfoUser,
      showQuickInfo: showQuickInfoOriginal
    } = useQuickInfo(true);

    const imageBorderClassObject = computed(() => ({
      width: `${size.value}px`,
      height: `${size.value}px`
    }));

    const serverRequestSize = computed(() => (useNativeBrowserScaling.value ? 120 : size.value));
    const el = templateRef<HTMLElement>('el');

    const isVisible = useElementVisibility(el);

    /*    watch(userId, () => {
      if (userId.value) avatarUserId.value = userId.value ?? 'user';
      // viewUser.value = (showOnlineIndicator.value || showCookies.value) ? store.getters['userStates/getUserInfo'](avatarUserId.value) : null;
      // Solange man das viech für Quickinfo anklicken kann, brauchen wir auch den viewUser:
      viewUser.value = store.getters['userStates/getUserInfo'](avatarUserId.value);
    }, {
      immediate: true
    }); */

    watchEffect(() => {
      if (userId.value) {
        avatarUserId.value = userId.value;
        viewUser.value = hasBeenVisible.value ? store.getters['userStates/getUserInfo'](avatarUserId.value) : null;
      }
    });

    // Get user information

    // Take online status and amount of cookies from the viewUser, if not overridden by the 2 props
    const showUserAsOnline = computed(() => (userIsOnline.value ?? viewUser.value?.online ?? viewUser.value?.isOnline ?? false));
    const displayedAmountOfCookies = computed(() => (amountOfCookies.value ?? viewUser.value?.cookies));

    const avatarImage: ComputedRef<AvatarCache> = computed(
      () => (hasBeenVisible.value
        ? store.getters['avatarCache/getUserAvatar'](
          avatarUserId.value,
          serverRequestSize.value,
          window.devicePixelRatio ?? 1
        ) : ''
      )
    );

    const avatarImageData = computed(() => {
      if (avatarImage.value.imageData === LOADING) {
        return LOADING;
      }
      if (avatarImage.value.imageData) {
        return `data:${avatarImage.value.imageType};base64,${avatarImage.value.imageData}`;
      }
      return '';
    });

    const showQuickInfo = () => {
      if (quickInfoUser.value?.userId === viewUser.value?.userId) {
        setShowContactHistory(true);
      } else {
        showQuickInfoOriginal(el, viewUser.value as User);
      }
    };

    const cookieSize = computed(() => {
      if (size.value < 40) {
        return 'small';
      } if (size.value > 40) {
        return 'large';
      }
      return 'medium';
    });

    whenever(isVisible, () => {
      hasBeenVisible.value = true;
    }, {
      immediate: true
    });

    useTimeoutPoll(() => {
      if (!avatarUserId.value || !isVisible.value || (documentVisibility.value === 'hidden')) return;
      store.dispatch('userStates/requestOnlineStatus', avatarUserId.value);
    }, REFRESH_INTERVAL_BANNER_ONLINE_STATUS, {
      immediate: true
    });

    return {
      el,
      imageBorderClassObject,
      avatarImageData,
      LOADING,
      showUserAsOnline,
      displayedAmountOfCookies,
      showQuickInfo,
      cookieSize,
      useNativeBrowserScaling,
      hasBeenVisible,
      viewUser,
    };
  },
});
