
import useResponsiveness from '@/mixins/useResponsiveness';
import { computed, defineComponent, toRefs } from 'vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import { SearchAvailability, UserSearchEntry } from '@/views/user-search/models/UserSearch';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import useUserSearch from '@/views/user-search/store/useUserSearch';
import { AvailabilityInterface } from '@/models/Availability';
import { SkillListItem } from '@/models/Profile';
import useUser from '@/mixins/useUser';
import { useStore } from 'vue2-helpers/vuex';
import UserAvailability from '@/components/UserAvailability.vue';

export default defineComponent({
  name: 'UserSearchItem',
  components: {
    UserAvailability,
    BCXUserBanner,
    TagDisplay,
  },
  props: {
    user: {
      type: Object as () => UserSearchEntry,
      required: true,
      default: () => ({}),
    },
    showTags: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const store = useStore();
    const { user } = toRefs(props);
    const { isLoading } = useUserSearch();
    const { isMobileWidth } = useResponsiveness();

    const profile = computed(() => store.getters['userStates/getUserInfo']((user.value.id ?? 'user')));
    const { isCustomer } = useUser(profile);

    const availability = computed(() => {
      const {
        availability, availableFrom, availableTo, availableHoursPerWeek
      } = user.value;
      const a: AvailabilityInterface = {
        available: availability !== SearchAvailability.Unavailable,
        availableFrom,
        availableTo,
        availableNow: !availableFrom || new Date(availableFrom).getTime() <= new Date().getTime(),
        availableFulltime: availability === SearchAvailability.Available,
        availableHoursPerWeek,
        overallStatusLabel: ''
      };
      return a;
    });

    const userLocation = computed(() => `${user.value.city}, ${user.value.country}`);
    const skills = computed<SkillListItem[]>(() => user.value?.skills?.map((skill, idx) => ({
      skillTagId: skill.skillId,
      skillTagName: skill.skillLabel,
      skillRoleTagId: skill.roleId,
      skillRoleTagName: skill.roleLabel,
      userSkillId: `${idx}`
    })) ?? []);

    const isAvailabilityVisible = computed(() => !isCustomer.value);

    return {
      isMobileWidth,
      isLoading,
      skills,
      userLocation,
      isAvailabilityVisible,
      availability,
    };
  },
});
