import { render, staticRenderFns } from "./BCXIcon.vue?vue&type=template&id=f5ff0240&scoped=true"
import script from "./BCXIcon.vue?vue&type=script&lang=ts"
export * from "./BCXIcon.vue?vue&type=script&lang=ts"
import style0 from "./BCXIcon.vue?vue&type=style&index=0&id=f5ff0240&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5ff0240",
  null
  
)

export default component.exports