
import useResponsiveness from '@/mixins/useResponsiveness';
import { computed, defineComponent, PropType } from 'vue';
import { NotificationListItem, NotificationTypeCounts } from '@/models/Notification';
import useMessengerState from '@/state/messenger/messengerState';
import { CHAT_TYPE_PROJECT, ChatFilter } from '@/models/Messenger';
import useNotificationState from '@/state/notificationState';
import useRouterIngoreDuplicate from '@/mixins/useRouterIngoreDuplicate';
import NotificationPanelProjectItem from '@/components/toolbar/notification/NotificationPanelProjectItem.vue';

export default defineComponent({
  name: 'NotificationPanelProjectItems',
  components: { NotificationPanelProjectItem },
  props: {
    items: { type: Array as PropType<NotificationListItem[]>, default: () => [] },
  },
  setup(props) {
    const {
      isOpened, isExpanded, groupFilters
    } = useMessengerState();
    const {
      isMobileWidth
    } = useResponsiveness();

    const { pushRoute } = useRouterIngoreDuplicate();

    const { resetInterval, showPanel } = useNotificationState();

    const gotoMessenger = async (type:string) => {
      // console.log('SetGroup called with', type);
      Object.assign(groupFilters, {
        all: false,
        [CHAT_TYPE_PROJECT]: type === CHAT_TYPE_PROJECT,
      } as ChatFilter);
      isOpened.value = true;
      isExpanded.value = !isMobileWidth.value;
      resetInterval();
      showPanel.value = false;
    };

    const gotoSubscriptions = async (filter:string) => {
      await pushRoute({
        name: 'subscriptions',
        query: {
          filter
        },
      });
      showPanel.value = false;
    };

    const gotoRecommendations = async () => {
      await pushRoute({
        name: 'recommendations',
        query: {
        },
      });
      showPanel.value = false;
    };

    const gotoProjectOffers = async () => {
      await pushRoute({
        name: 'myProjects',
        query: {
        },
      });
      showPanel.value = false;
    };

    const gotoItem = async (itemName:NotificationTypeCounts) => {
      switch (itemName) {
        case NotificationTypeCounts.ProjectMessage:
          await gotoMessenger(CHAT_TYPE_PROJECT);
          break;

        case NotificationTypeCounts.NewProjectChanges:
        case NotificationTypeCounts.NewProjects:
        case NotificationTypeCounts.NewProjectComments:
          await gotoSubscriptions('PROJECTS');
          break;
        case NotificationTypeCounts.NewRecommendations:
        case NotificationTypeCounts.NewRecommendationFeedbacks:
          await gotoRecommendations();
          break;
        case NotificationTypeCounts.NewProjectOffers:
        case NotificationTypeCounts.ChangedProjectOffers:
          await gotoProjectOffers();
          break;
        default:
      }
    };

    const projectItems = computed(() => {
      const projectTypes = [NotificationTypeCounts.NewProjectChanges, NotificationTypeCounts.NewProjectComments, NotificationTypeCounts.NewProjects];
      return props.items.filter((item) => projectTypes.includes(item.name)).filter((item) => item.count > 0);
    });

    const recommendationItems = computed(() => {
      const recommendationTypes = [NotificationTypeCounts.NewRecommendations, NotificationTypeCounts.NewRecommendationFeedbacks];
      return props.items.filter((item) => recommendationTypes.includes(item.name)).filter((item) => item.count > 0);
    });

    const projectOfferItems = computed(() => {
      const projectOfferTypes = [NotificationTypeCounts.NewProjectOffers];
      return props.items.filter((item) => projectOfferTypes.includes(item.name)).filter((item) => item.count > 0);
    });

    const changedProjectOfferItems = computed(() => {
      const projectOfferTypes = [NotificationTypeCounts.ChangedProjectOffers];
      return props.items.filter((item) => projectOfferTypes.includes(item.name)).filter((item) => item.count > 0);
    });

    return {
      gotoItem, projectItems, recommendationItems, projectOfferItems, changedProjectOfferItems
    };
  }
});
